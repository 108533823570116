.our-clients {
    border: 1px solid rgba(151, 151, 151, 0.1);
    background-color: rgba(136, 136, 150, 0.1);
    padding: 32px 30px;
  
    h6 {
      color: #191A25;
      font-family: 'Berlin Sans FB Regular';
      font-size: 15px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      margin-bottom: 16px;
    }
  
    &-brands {
      grid-template-columns: repeat(7, minmax(0, 1fr));
      display: grid;
      grid-gap: 12px 35px;
      opacity: 0.5;

      img {
        display: block;
        margin: auto;
        object-fit: contain;
        max-width: 140px;
        height: 70px;
      }
  
      @media (max-width: 1200px) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }
  
      @media (max-width: 992px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
  
      @media (max-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
  
      @media (max-width: 576px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
  }